import { ChangeEvent, Fragment, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import classNames from 'classnames';

import {
	CountryMobileNumber,
	CustomDatePicker,
	Input,
	Loader,
	ReactSwitch,
} from '@storybook';
import {
	BusinessCompanyMemberListError,
	COMPANY_NONE_MEMBER_FORM,
	COMPANY_SELECTED_MEMBER_FORM,
	defaultCustomMember,
} from 'views/kyb/constants';
import {
	IKYBCompanyMemberListState,
	RepresentativeDataThroughKyc,
	RepresentativeFilledToggleState,
	SearchKybCompanyMembersInputState,
	SearchKybCompanyMembersListState,
	TheKYBCompanyMemberListState,
	TheKybMemberListLoader,
} from 'views/kyb/stores';
import { RepresentativeFilledHeader } from '../representative-detail/constant';
import { useKYBRequests } from 'views/kyb/stores/hooks';
import {
	isValidORDateGreaterThanToday,
	validateDOB,
	validateEmail,
} from 'utils';

interface SelectCompanyMemberProps {
	setIsPhoneValid: (isValid: boolean) => void; // Explicitly define the type for setIsPhoneValid
}
export const SelectCompanyMember = ({
	setIsPhoneValid,
}: SelectCompanyMemberProps) => {
	const [businessCompanyMemberList, setBusinessCompanyMemberList] =
		useRecoilState(TheKYBCompanyMemberListState);
	// this state is only for search input
	const searchKybMembersList = useRecoilValue(SearchKybCompanyMembersListState);
	const searchKybMembersInputValue = useRecoilValue(
		SearchKybCompanyMembersInputState
	);
	const memberListLoading = useRecoilValue(TheKybMemberListLoader);

	const [error, setError] = useState({
		date_of_birth: '',
		email: '',
		national_id_number: '',
		phone: '',
	});

	const isToggleOn = useRecoilValue(RepresentativeFilledToggleState);
	const representativeData: any = useRecoilValue(RepresentativeDataThroughKyc);

	const { changeSelectedMemberToggle, showPrefilledToggle, isSameMemberAsKYC } =
		useKYBRequests();

	const getInitials = (name: string) => {
		if (name === defaultCustomMember.id) {
			return '?';
		}
		const words = name?.split?.(' ') ?? [];
		const initials = words.map(word => word.charAt(0)).join('');
		return initials.substring(0, 2);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleValidation = (isValid: boolean) => {
		setIsPhoneValid(isValid);
	};
	const handleInputChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, index: number) => {
			const { name, value } = e?.target ?? {};
			if (name === 'first_name' || name === 'last_name') {
				const alphabetRegex = /^[a-zA-Z\s]+$/;
				if (!value?.length) {
					setError(prev => ({
						...prev,
						[name]: `${name === 'first_name' ? 'First name' : 'Last name'} is Required`,
					}));
				} else if (!alphabetRegex.test(value)) {
					setError(prev => ({
						...prev,
						[name]: `${name === 'first_name' ? 'First name' : 'Last name'} must contain only alphabets`,
					}));
				} else {
					setError(prev => ({
						...prev,
						[name]: '',
					}));
				}
				setBusinessCompanyMemberList((prev: IKYBCompanyMemberListState) => {
					const pre = JSON.parse(JSON.stringify(prev));
					pre.members[index] = {
						...pre.members[index],
						[name]: value,
					};
					return { ...pre, activeIndex: index };
				});
			} else {
				if (name === 'date_of_birth' && isValidORDateGreaterThanToday(value)) {
					setError(prev => ({
						...prev,
						[name]: 'Invalid date',
					}));
					setBusinessCompanyMemberList((prev: IKYBCompanyMemberListState) => {
						const pre = JSON.parse(JSON.stringify(prev));
						pre.members[index] = {
							...pre.members[index],
							[name]: '',
						};
						return { ...pre, activeIndex: index };
					});
					return;
				}

				if (name === 'date_of_birth' && !validateDOB(value)) {
					setError(prev => ({
						...prev,
						[name]: BusinessCompanyMemberListError.DOB,
					}));
					setBusinessCompanyMemberList((prev: IKYBCompanyMemberListState) => {
						const pre = JSON.parse(JSON.stringify(prev));
						pre.members[index] = {
							...pre.members[index],
							[name]: '',
						};
						return { ...pre, activeIndex: index };
					});
					return;
				}

				if (name === 'national_id_number') {
					const number = value;
					const validSsnRegex = /^[0-9a-zA-Z/-]*( [0-9a-zA-Z/-]+)*$/;
					const digits = number?.replace(/[^0-9a-zA-Z]/g, '');
					const ssnValue = number
						.replace(/[^0-9a-zA-Z/\- ]/g, '')
						.replace(/\s+/g, ' ');

					if (!validSsnRegex.test(number.trim())) {
						setError(prev => ({
							...prev,
							[name]: BusinessCompanyMemberListError.SSN_INVALID_DIGIT,
						}));
					}
					if (digits.length > 15 && validSsnRegex.test(number.trim())) {
						setError(prev => ({
							...prev,
							[name]: BusinessCompanyMemberListError.SSN_FIFTEEN_DIGIT,
						}));
					}
					if (digits.length < 9 && validSsnRegex.test(number.trim())) {
						setError(prev => ({
							...prev,
							[name]: BusinessCompanyMemberListError.SSN_NINE_DIGIT,
						}));
					}
					if (
						digits.length >= 9 &&
						digits.length < 15 &&
						validSsnRegex.test(number.trim())
					) {
						setError(prev => ({
							...prev,
							[name]: '',
						}));
					}

					if (digits.length <= 15 && validSsnRegex.test(number.trim())) {
						const isOnlyDigitsRegex = /^[a-zA-Z0-9]+$/;
						const valueToSet = isOnlyDigitsRegex.test(number.trim())
							? number.replace(/(\w{3})(\w{2})(\w{4})/, '$1-$2-$3')
							: ssnValue;
						return setBusinessCompanyMemberList(
							(prev: IKYBCompanyMemberListState) => {
								const pre = JSON.parse(JSON.stringify(prev));
								pre.members[index] = {
									...pre.members[index],
									[name]: valueToSet,
								};
								return { ...pre, activeIndex: index };
							}
						);
					}
					return;
				}

				if (name === 'email' && (!value.length || validateEmail(value))) {
					setError(prev => ({
						...prev,
						[name]: BusinessCompanyMemberListError.EMAIL_INVALID,
					}));
				} else {
					setError(prev => ({
						...prev,
						[name]: '',
						email: '',
						date_of_birth: '',
						national_id_number: '',
					}));
				}
				setBusinessCompanyMemberList((prev: IKYBCompanyMemberListState) => {
					const pre = JSON.parse(JSON.stringify(prev));
					pre.members[index] = {
						...pre.members[index],
						[name]: value,
					};
					return { ...pre, activeIndex: index };
				});
			}
		},
		[setBusinessCompanyMemberList]
	);

	const getValueInput = useCallback(
		(key: string, index: number) => {
			if (key === 'national_id_number') {
				if (
					(businessCompanyMemberList.members[index]?.[key] as string)?.length <=
					9
				) {
					return (
						(
							businessCompanyMemberList.members[index]?.[key] as string
						)?.replace(/(\w{3})(\w{2})(\w{4})/, '$1-$2-$3') ?? ''
					);
				}
				if (
					(businessCompanyMemberList.members[index]?.[key] as string)?.length >
					9
				) {
					return businessCompanyMemberList.members[index]?.[key] as string;
				}
				return;
			} else {
				return (
					(businessCompanyMemberList.members[index]?.[key] as string) ?? ''
				);
			}
		},
		[businessCompanyMemberList]
	);

	const getDateValue = useCallback(
		(index: number) => {
			const date =
				businessCompanyMemberList.members?.[index]?.['date_of_birth'] ?? {};
			if (date && typeof date === 'string') {
				const updateDate = new Date(date);
				const paylaod = {
					day: updateDate.getDate(),
					month: updateDate.getMonth() + 1,
					year: updateDate.getFullYear(),
				};
				return paylaod;
			}
			if (Object.keys(date).length > 0) {
				return date;
			}
			return null;
		},
		[businessCompanyMemberList.members]
	);

	const onHandlePhoneChange = useCallback(
		(countryCode: string | number, phone: string, index: number) => {
			const number = phone;
			if ((/^\d+$/.test(phone) || phone === '') && phone.length <= 12) {
				setBusinessCompanyMemberList((prev: any) => {
					const pre = JSON.parse(JSON.stringify(prev));
					pre.members[index] = {
						...pre.members[index],
						phone: number,
						country_code: countryCode,
					};
					return { ...pre, activeIndex: index };
				});
				if (phone.length < 8) {
					setError(prev => ({
						...prev,
						['phone']: BusinessCompanyMemberListError.PHONE_INVALID,
					}));
				} else {
					setError(prev => ({
						...prev,
						['phone']: '',
					}));
				}
				return;
			}
		},
		[setBusinessCompanyMemberList]
	);

	const onHandleChangeCountry = useCallback(
		(countryCode: string | number, index: number) => {
			setBusinessCompanyMemberList((prevState: any) => {
				const pre = JSON.parse(JSON.stringify(prevState));
				pre.members[index] = {
					...pre.members[index],
					country_code: countryCode,
				};
				return { ...pre, activeIndex: index };
			});
		},
		[setBusinessCompanyMemberList]
	);

	const selectedMemberForm = useCallback(
		(form: any, index: number) => {
			return form.map((el: any) => {
				switch (el.type) {
					case 'text':
						return (
							<Fragment key={`${el.name}_${index.toString()}`}>
								<Input
									label={el.label}
									inputType={el.type}
									placeholder={el.placeHolder}
									handleChange={e => handleInputChange(e, index)}
									inputName={el.name}
									value={getValueInput(el.name, index)}
									disabled={isToggleOn && representativeData[el.name as any]}
									isRequired
									errorMessage={error[el.name as 'email']}
									isError={!!error[el.name as 'email']}
								/>
							</Fragment>
						);

					case 'phone':
						return (
							<CountryMobileNumber
								label={el.label}
								handleChange={e =>
									onHandlePhoneChange(e.countryCode, e.phone, index)
								}
								defaultCountryCode={
									getValueInput('country_code', index) || '+1'
								}
								defaultNumber={getValueInput('phone', index)}
								handleChangeCountry={e => onHandleChangeCountry(e, index)}
								disabled={isToggleOn && representativeData[el.name as any]}
								isRequired
								errorMessage={error.phone}
								isError={!!error['phone']}
								handleValidation={handleValidation}
							/>
						);

					case 'date':
						return (
							<CustomDatePicker
								value={getDateValue(index)}
								label={el.label}
								onChange={(e: any) => handleInputChange(e, index)}
								name="date_of_birth"
								isError={!!error['date_of_birth']}
								errorMessage={error['date_of_birth']}
								disabled={isToggleOn && representativeData[el.name as any]}
								isRequired
							/>
						);

					default:
						return <></>;
				}
			});
		},
		[
			error,
			getDateValue,
			getValueInput,
			handleInputChange,
			isToggleOn,
			onHandlePhoneChange,
			representativeData,
			onHandleChangeCountry,
			handleValidation,
		]
	);

	const renderCard = useCallback(
		(name: string, designation: string, index: number) => {
			return (
				<div className="kyb-members__card__wrapper">
					<div className="kyb-members__card__para">{getInitials(name)}</div>
					<div className="kyb-members__card__initial">
						<div className="kyb-members__card__initial__text">
							{name === defaultCustomMember.id
								? defaultCustomMember.name
								: name}
						</div>
						<div className="kyb-members__card__initial__designation">
							{name === defaultCustomMember.id
								? defaultCustomMember.name
								: designation}
						</div>
					</div>
					<input
						type="radio"
						name="selectMemberCard"
						checked={businessCompanyMemberList.activeIndex === index}
						className="kyb-list__radio-button"
					/>
				</div>
			);
		},
		[businessCompanyMemberList.activeIndex]
	);

	const handleOpenForm = useCallback(
		(index: number) => {
			if (index === businessCompanyMemberList.activeIndex) return;
			setBusinessCompanyMemberList(pre => ({ ...pre, activeIndex: index }));
			setError({
				email: '',
				date_of_birth: '',
				national_id_number: '',
				phone: '',
			});
		},
		[businessCompanyMemberList.activeIndex, setBusinessCompanyMemberList]
	);

	const renderFilledToggle = useCallback(
		(name: string, index: number) => {
			return (
				isSameMemberAsKYC(name) &&
				showPrefilledToggle() && (
					<div className="Representative-detail--filledWrapper">
						<div className="Representative-detail--filledHeader">
							<div className="Representative-detail--filledTitle">
								{RepresentativeFilledHeader.title}
							</div>
							<div className="Representative-detail--filledDesc">
								{RepresentativeFilledHeader.desc}
							</div>
						</div>
						<div className="Representative-detail--filledToggle">
							<ReactSwitch
								checked={isToggleOn}
								handleChange={(value: boolean) =>
									changeSelectedMemberToggle(value, index)
								}
								id={'representative-filled-toggle'}
							/>
						</div>
					</div>
				)
			);
		},
		[
			changeSelectedMemberToggle,
			isSameMemberAsKYC,
			isToggleOn,
			showPrefilledToggle,
		]
	);

	const kybMemberClass = useCallback(
		(index: number | null) => {
			return classNames('kyb-members__card', {
				'kyb-members__card_active':
					businessCompanyMemberList.activeIndex === index,
			});
		},
		[businessCompanyMemberList.activeIndex]
	);

	// get all kyb company members
	const kybCompanyMemberList = useMemo(() => {
		return searchKybMembersInputValue.length >= 1
			? searchKybMembersList
			: businessCompanyMemberList;
	}, [
		businessCompanyMemberList,
		searchKybMembersInputValue.length,
		searchKybMembersList,
	]);
	const memeberListLoader = useMemo(
		() =>
			!memberListLoading ? (
				<div className="kyb-list__loader-wrapper">
					<div className="kyb-list__loader-box">
						<Loader type="loader" dimension={20} />{' '}
					</div>
					<div>Please wait fetching more members.</div>
				</div>
			) : null,
		[memberListLoading]
	);

	return (
		<div className="kyb-members">
			{(kybCompanyMemberList?.members ?? []).map((member, index) => {
				const { name, designation, id } = member;

				return (
					<div
						key={id}
						className={kybMemberClass(index)}
						onClick={() => handleOpenForm(index)}
					>
						{renderCard(name, designation, index)}
						{id === 'custom' &&
						businessCompanyMemberList.activeIndex ===
							businessCompanyMemberList.members.length - 1 ? (
							<div className="kyb-members__card__input-wrapper">
								{renderFilledToggle(name, index)}
								{selectedMemberForm(
									businessCompanyMemberList.members.length - 1 === index
										? COMPANY_NONE_MEMBER_FORM
										: COMPANY_SELECTED_MEMBER_FORM,
									index
								)}
							</div>
						) : null}
					</div>
				);
			})}
			{memeberListLoader}
		</div>
	);
};
