import { BodyWrapper, LabelElement } from 'components';
import { useCallback, useMemo, useState, useRef, useEffect } from 'react';

import './kyb-form.scss';
import { Button, Input, Loader } from '@storybook';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SessionDetailsState } from 'hooks/use-next-step/stores';
import {
	useNextStep,
	useNotification,
	useSharedVariables,
	useTokenSession,
} from 'hooks';
import { API_TYPE } from 'constants/api';
import { AccessTokenState, isShowSkipState } from 'states';
import { dolaterSuccess } from 'views/constants';

export const KybForm = () => {
	// local states
	const [optionSelected, setOptionSelected] = useState<any>({});
	const [dropOptionFlag, setDropOptionFlag] = useState(false);
	const [bodyElemFlag, setBodyElemFlag] = useState(false);
	const [selectFileName, setSelectFileName] = useState([]);
	const [selectDocName, setselectDocName] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [isSkipLoading, setIsSkipLoading] = useState(false);

	// Global states
	const sessionDetails = useRecoilValue(SessionDetailsState);
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const { code: sessionCode } = useRecoilValue(AccessTokenState);

	// hooks
	const { postTokenSession } = useTokenSession();
	const { getKybForm, nodeIdFromQueryParam } = useSharedVariables();
	const { successNotification, warningNotification, errorNotification } =
		useNotification();
	const { setSessionDetails, sessionPayloadDetail, handleNext } = useNextStep();
	const { currentAction } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);

	const kybFromEntities = useMemo(() => {
		if (
			sessionDetails?.nodes?.key === 'kybForm' ||
			sessionDetails?.nodes?.actions?.[0]?.key === 'kybForm'
		) {
			return sessionDetails?.nodes?.actions?.[0]?.metadata?.questions ?? [];
		} else {
			return [];
		}
	}, [sessionDetails]);

	const { userId, pipelineId } = sessionDetails;

	useEffect(() => {
		if (sessionDetails?.existingKybFormAction) {
			const isChoices = sessionDetails?.existingKybFormAction?.choices;
			if (isChoices && typeof isChoices === 'object') {
				const reFactorChoice = Object.keys(isChoices).map(key => {
					return {
						value: key,
						text: isChoices[key]?.text,
					};
				});

				setOptionSelected({
					choices: reFactorChoice,
					title: sessionDetails?.existingKybFormAction?.title,
				});
				setBodyElemFlag(true);
			}
		}
	}, [sessionDetails]);

	const handelDropOption = useCallback(
		(key: any) => {
			setDropOptionFlag(true);
			setOptionSelected(key);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dropOptionFlag]
	);

	const handelKybFormNext = useCallback(() => {
		setBodyElemFlag(true);
	}, []);

	const handelKybFormBack = useCallback(() => {
		setBodyElemFlag(false);
		setDropOptionFlag(false);
		setOptionSelected('');
		setSelectFileName([]);
		setSessionDetails(prev => ({ ...prev, existingKybFormAction: {} }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isSubmitDisable = useMemo(() => {
		const Checkvalue = selectFileName.filter((data: any) => data?.fileName);
		return !Checkvalue.length;
	}, [selectFileName]);

	const handelKybFormSubmit = useCallback(
		async (isDoLater?: boolean) => {
			if (!isDoLater) {
				setIsLoading(true);
			} else {
				setIsSkipLoading(true);
			}
			const choiceData: any = {};

			if (!isDoLater) {
				selectFileName?.map((data: any) => {
					if (data?.value && data?.base64) {
						choiceData[data.value] = {
							text: data?.id ?? '',
							answer: data?.base64 ?? '',
						};
					}
				});
			} else {
				optionSelected?.choices.map((data: any) => {
					if (data?.value) {
						choiceData[data.value] = {
							text: data?.text ?? '',
							answer: '',
						};
					}
				});
			}

			const removeColonTitle = optionSelected?.title?.replaceAll(':', '');
			const kybPayload = {
				pipelineId: pipelineId ?? '',
				userId: userId ?? '',
				nodeId: nodeIdFromQueryParam ?? currentAction._id ?? '',
				actions: [
					{
						id: 'kybForm',
						data: {
							survey: {
								[optionSelected.name ?? sessionDetails?.questions]: {
									title: removeColonTitle,
									displayValue: optionSelected.choices
										.map((data: any) => data.text)
										.toString(),
									choices: choiceData,
								},
							},
						},
					},
				],
			};
			// COMPLEX_SESSION patch api
			const payload = {
				code: sessionCode,
				type: API_TYPE.UPDATE,
				payload: kybPayload,
			};
			const res = await postTokenSession(payload);
			if (res?.statusCode === 200) {
				if (!isDoLater) {
					successNotification('KYB Doc Submitted Successfully');
				}
				const kybResponse = { ...res };
				delete kybResponse?.statusCode;

				setSessionDetails(prev => ({
					...prev,
					nodes: getKybForm ? dolaterSuccess : kybResponse,
					fundName: getKybForm ? '' : res?.fundName ?? '',
					investingAmount: res?.investingAmount ?? '',
				}));

				if (!getKybForm) {
					handleNext({
						...sessionDetails,
						nodes: kybResponse,
					});
				}
			} else {
				if (
					res?.statusCode === 0 ||
					res?.statusCode === undefined ||
					res?.statusCode === null
				) {
					warningNotification(
						'No response received. This might be due to a network problem. Please retry.'
					);
				} else {
					errorNotification('Something Went Wrong ');
				}
				setIsShowSkip(true);
			}

			setIsLoading(false);
			setIsSkipLoading(false);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[
			optionSelected?.title,
			optionSelected.name,
			optionSelected.choices,
			pipelineId,
			userId,
			nodeIdFromQueryParam,
			currentAction._id,
			sessionDetails,
			sessionCode,
			postTokenSession,
			selectFileName,
			setSessionDetails,
			getKybForm,
			successNotification,
			handleNext,
			setIsShowSkip,
			warningNotification,
			errorNotification,
		]
	);

	const hiddenFileInput: any = useRef(null);

	const convertFileToBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = reject;
		});
	};

	const handleClick = useCallback(
		(valueLable: any) => {
			hiddenFileInput?.current?.click(valueLable?.text);
			const fileObjList: any =
				selectFileName.filter((file: any) => file.id !== valueLable?.text) ||
				[];
			setselectDocName(valueLable);
			fileObjList.push({ id: valueLable?.text, value: valueLable?.value });
			setSelectFileName(fileObjList);
		},
		[selectFileName]
	);

	const handleChange = useCallback(
		async (event: any) => {
			const fileUploaded = event.target?.files[0];
			const validTypes = [
				'application/pdf',
				'image/jpeg',
				'image/png',
				'image/jpg',
			];
			if (!validTypes.includes(fileUploaded.type)) {
				errorNotification(
					'Please upload a PDF or JPG, JPEG, or PNG image file only.'
				);
				return;
			}
			const base64String =
				fileUploaded && ((await convertFileToBase64(fileUploaded)) as string);
			setSelectFileName((prevState: any) => {
				const fileObjList: any =
					prevState.filter((file: any) => file.id !== selectDocName?.text) ||
					[];
				fileObjList.push({
					id: selectDocName?.text,
					value: selectDocName?.value,
					fileName: fileUploaded?.name || '',
					base64: base64String,
				});
				return fileObjList;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectDocName]
	);

	const getFileName = useCallback(
		(documentLabel: string) => {
			const fileObj: any = selectFileName.find(
				(file: any) => file.id === documentLabel
			);
			if (fileObj) return fileObj?.fileName ?? '';

			return '';
		},
		[selectFileName]
	);
	const getNameWithElipses = useCallback(
		(label: string, isRequired?: boolean) => {
			return (
				<div className="elpisis-wrapper">
					<div className="elipsis__label">{label}</div>
					{isRequired && '(Required)'}
				</div>
			);
		},
		[]
	);
	const handelInputTag = useCallback(
		(valueLable: any, isRequired: boolean, index?: any) => (
			<div className="browse_container" key={index}>
				{/* Paras: add id for input */}
				<Input
					inputType="text"
					placeholder=""
					value={getFileName(valueLable?.text)}
					label={getNameWithElipses(valueLable?.text, isRequired)}
					handleChange={() => {}}
					id="browse_container__input"
				/>
				<div className="browse_container__button_container">
					<Button
						label={
							<span id="buttonid" className="browse_container__label">
								Browse
							</span>
						}
						handleClick={() => handleClick(valueLable)}
						type="button__filled button__filled button__small button__block"
					/>
					<input
						type="file"
						onChange={handleChange}
						ref={hiddenFileInput}
						onClick={(e: any) => (e.currentTarget.value = null)}
						style={{ display: 'none' }}
						accept="application/pdf, image/jpeg, image/png, image/jpg"
					/>
				</div>
			</div>
		),
		[getFileName, getNameWithElipses, handleChange, handleClick]
	);

	const renderBodyElement = useMemo(
		() => (
			<>
				<div className="kyb-form__sub-header__wrapper">
					<span className="kyb-form__sub-header__title">
						Select your entity type
					</span>
					<span className="kyb-form__sub-header__description">
						Please select your entity type
					</span>
				</div>
				<div className="kyb-form__container">
					{(kybFromEntities ?? [])?.map((key: any, i: any) => (
						<div
							className={`kyb-form__drop-option__container${
								dropOptionFlag && optionSelected?.title === key.title
									? '-select'
									: ''
							}`}
							key={`${key.title}__${i}`.toString()}
						>
							<div
								className="kyb-form__drop-option__title-wrapper"
								onClick={() => handelDropOption(key)}
							>
								<input
									type="radio"
									checked={
										dropOptionFlag && optionSelected?.title === key?.title
									}
									className="react-flow__handle-left"
								/>
								<span className="kyb-form__drop-option__lable-name">
									{key.title?.split(':')}
								</span>
								<i className="ri-arrow-down-s-line" />
							</div>
							{optionSelected?.choices?.length > 0 &&
								dropOptionFlag &&
								optionSelected?.title === key?.title && (
									<div className="kyb-form__drop-option__description-wrapper">
										<span className="kyb-form__drop-option__description-name">
											{key?.choices?.map((valueLable: any, i: any) => (
												<div
													key={`${valueLable?.text}__${i.toString()}`}
													className="kyb-form__drop-option__description-subname"
												>
													{valueLable?.text}
												</div>
											))}
										</span>
									</div>
								)}
						</div>
					))}
				</div>
				<div className="div_form_button">
					<Button
						label="Next"
						handleClick={() => handelKybFormNext()}
						type="button__filled button__filled--primary button__large button__block"
						disabled={dropOptionFlag ? false : true}
					/>
				</div>
			</>
		),
		[
			optionSelected,
			dropOptionFlag,
			handelDropOption,
			handelKybFormNext,
			kybFromEntities,
		]
	);

	const bodyElementEntity = useMemo(
		() => (
			<div className="kyb_form_container">
				<div className="kyb-form__sub-header__wrapper">
					<span className="kyb-form__sub-header__title">
						Upload supporting documents for entity verification
					</span>
					<span className="kyb-form__sub-header__description">
						Please upload all the required document
					</span>
				</div>

				<div className="kyb_div_container__entity">
					{optionSelected?.choices?.map((valueLable: any, i: any) =>
						handelInputTag(valueLable, false, i)
					)}
					{handelInputTag({ text: 'Any other document', value: 'any1' }, false)}
				</div>

				<div className="kyb_div_container__button">
					<div className="split-button-container">
						<Button
							label={
								isLoading ? <Loader type="circle" dimension={26} /> : 'Submit'
							}
							disabled={isSubmitDisable}
							handleClick={() => handelKybFormSubmit()}
							type="button__filled button__filled--primary button__large button__block splited-button"
						/>
					</div>
				</div>
			</div>
		),
		[
			optionSelected,
			handelInputTag,
			handelKybFormSubmit,
			isSubmitDisable,
			isLoading,
		]
	);

	const kybBackBtn = useMemo(() => {
		return (
			<>
				{bodyElemFlag && (
					<Button
						type="header-back-btn"
						disabled={isLoading}
						handleClick={handelKybFormBack}
						label={<i className="ri-arrow-left-line"></i>}
					/>
				)}
			</>
		);
	}, [handelKybFormBack, bodyElemFlag, isLoading]);

	const kybskipBtn = useMemo(() => {
		return (
			<>
				{bodyElemFlag && (
					<Button
						type="skip-btn"
						disabled={isLoading}
						handleClick={() => handelKybFormSubmit(true)}
						label={
							<>
								Skip for now<i className="ri-arrow-right-s-line"></i>
							</>
						}
					/>
				)}
			</>
		);
	}, [handelKybFormSubmit, bodyElemFlag, isLoading]);

	if (isSkipLoading) {
		return (
			<div className="loading-steps">
				<Loader />
			</div>
		);
	}

	return (
		<div className="terms-header__container">
			<BodyWrapper
				label={
					<div className="terms-header__wrapper">
						<div className="terms_header">
							<LabelElement
								text={'KYB Form'}
								backBtn={kybBackBtn}
								skipBtn={kybskipBtn}
							/>
						</div>
					</div>
				}
				bodyElement={!bodyElemFlag ? renderBodyElement : bodyElementEntity}
			/>
		</div>
	);
};
